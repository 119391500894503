// TODO: this will change when working on code in the routes dir SPP-416
const formEnroll = (hasAcmCookie: boolean, placeholder: string) => {
  return [
    {
      input: [
        {
          name: 'email',
          placeholder,
          interface: 'email',
          inputStyle: 'achievement',
          required: !hasAcmCookie,
          hidden: hasAcmCookie,
        },
      ],
    },
  ];
};

const formLogin = (emailPlacholder: string, passwordPlaceHolder: string) => {
  return [
    {
      input: [
        {
          name: 'email',
          placeholder: emailPlacholder,
          interface: 'email',
          inputStyle: 'achievement',
          required: true,
        },
        {
          name: 'password',
          placeholder: passwordPlaceHolder,
          interface: 'passwordOnly',
          inputStyle: 'achievement',
          required: true,
        },
      ],
    },
  ];
};

const blackfynnAccountCreate = (placeholder: string) => {
  return [
    {
      input: [
        {
          name: 'email',
          placeholder,
          interface: 'email',
          inputStyle: 'achievement',
          required: true,
        },
      ],
    },
  ];
};

export default { formEnroll, formLogin, blackfynnAccountCreate };

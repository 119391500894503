import {
  EMAIL_REGEX,
  ENROLLMENT_NODE_STATUSES_DISPLAYABLE,
  ENROLLMENT_STATUSES_NON_LOGINABLE,
  NON_LOGINABLE_LAYOUTS,
  NON_LOGOUTABLE_LAYOUTS,
  STUDY_ID,
  URL_REGEX,
} from '../constants';

import _ from 'lodash';
import { defaultNodeValue, Node } from '../types/api';

export const isValidUrl = (url: string) => URL_REGEX.test(url); // false with '', undefined and null

export const isValidEmail = (email: string) =>
  EMAIL_REGEX.test(String(email).toLowerCase());

export const getQueryString = (field: string, url = window.location.href) => {
  const parsed = new URL(url);
  return parsed.searchParams.get(field) || '';
};

// use lodash instead of RegExp for IE support
export const pathContains = (path: string, candidates: Array<string>) => {
  return _.some(_.map(candidates, (c) => _.includes(path, c)));
};

// String#slice and String#indexOf work fine
export const getUrlParams = (search = ''): { [key: string]: string } => {
  if (_.isEmpty(search)) {
    return {};
  }

  const query = search.slice(search.indexOf('?') + 1);
  let hashes = _.split(query, '&');

  return _.reduce(
    hashes,
    (acc, hash) => {
      let [key, val] = hash.split('=');
      return { ...acc, [key]: decodeURIComponent(val) };
    },
    {} as { [key: string]: string },
  );
};

export const formatPhone = (phoneNum: string) => {
  var regexObj =
    /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (regexObj.test(phoneNum)) {
    const parts = phoneNum.match(regexObj);
    if (parts) {
      var phone = '';
      if (parts[1]) {
        phone += '+1 (' + parts[1] + ') ';
      }
      phone += parts[2] + '-' + parts[3];
      return phone;
    }
  }
  // otherwise invalid phone number
  return phoneNum;
};

export const nextNode = (nodes: Array<Node> = []) =>
  _.find(nodes, (obj) =>
    _.includes(ENROLLMENT_NODE_STATUSES_DISPLAYABLE, obj.status),
  ) || {};

//
// Achievement Login
export const canLogin = (
  status: string,
  layout: string,
  email: string | undefined,
  hasAcmCookie: boolean,
  is_program: boolean = false,
) => {
  if (is_program) return false;
  if (hasAcmCookie && !email) return true;

  return (
    !_.includes(ENROLLMENT_STATUSES_NON_LOGINABLE, status) &&
    !_.includes(NON_LOGINABLE_LAYOUTS, layout) &&
    !hasAcmCookie
  );
};

//
// Login & Logout

export const isEnrolled = (status: string, layout: string) => {
  switch (status) {
    case 'study_completed':
    case 'enrollment_completed':
      return true;

    case 'pending':
    case 'timing_out':
    case 'withdrawing':
    case 'disqualifying':
      return layout === 'dashboard';

    default:
      return false;
  }
};

export const canLogout = (
  loginable: boolean,
  enrolled: boolean,
  portalEmail: string | undefined,
  hasAcmCookie: boolean,
  layout: string,
  is_program: boolean,
) => {
  return (
    !is_program &&
    !loginable &&
    !_.includes(NON_LOGOUTABLE_LAYOUTS, layout) &&
    (enrolled || (portalEmail && hasAcmCookie))
  );
};

export const curiousPandaParticipantProp = ({
  enrollment_identifier,
  participant_auth_token,
  auth_token,
  nodes = [defaultNodeValue],
}: {
  enrollment_identifier: string;
  participant_auth_token: string;
  auth_token?: string;
  nodes: Array<Node>;
}) => {
  const [{ slug }] = nodes;
  return {
    enrollment_identifier,
    participant_auth_token,
    auth_token,
    node_slug: slug,
  };
};

/**
 * Used when trying to make the local text human readable on billboard.
 * Takes in a locale eg: 'es' , 'en_US' and returns everything BEFORE the "_" underscore if found.
 * otherwise returns the given string.
 * @param  {string} locale
 * @returns {string}
 */
export const getDisplayTextFromLocale = (locale: string) =>
  locale.split('_')[0];

// Allows setting a custom study slug in the context of a test.
export const getStudySlug = () => STUDY_ID;

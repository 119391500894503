export enum ParticipantActionType {
  USER_LOGOUT = 'USERLOGOUT',
  USER_SET_AUTH_TOKEN_EXPIRATION = 'USER/SET_AUTH_TOKEN_EXPIRATION',
  USER_LOGIN = 'USER/LOGIN',
  USER_UPDATE_ACM = 'USER/UPDATE_ACM',
  SURVEY_SUBMIT_SUCCESS = 'SURVEY/SUBMIT_SUCCESS',
  USER_REQUEST = 'USER/REQUEST',
}

export enum ErrorActionType {
  ERROR_ADD = 'ERRORS/ADD',
  ERROR_CLEAR = 'ERRORS/CLEAR',
}

export enum SurveyActionType {
  SUBMIT_SUCCESS = 'SURVEY/SUBMIT_SUCCESS',
  SUBMIT = 'SURVEY/SUBMIT',
}

export enum InteractionActionType {
  CLOSE_REQUEST = 'INTERACTION/CLOSE_REQUEST',
  CLOSE_SUCCESS = 'INTERACTION/CLOSE_SUCCESS',
}

export enum LandingActionType {
  RECEIVE = 'LANDING/RECEIVE',
  REQUEST = 'LANDING/REQUEST',
}

export enum MetaActionType {
  RECEIVE = 'META/RECEIVE',
  FAILURE = 'META/FAILURE',
  REQUEST = 'META/REQUEST',
}

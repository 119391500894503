import Close from './close';
// TODO It is necessary to generate and include the types for the @types/evidation__form-elements library
// to be done on SPP-1598
//@ts-expect-error
import { MarkdownHtml as Markdown } from '@evidation/form-elements';
import Modal from 'styled-react-modal';
import React from 'react';
import styled from 'styled-components';

type StyledModalProps = {
  opacity: number;
};

export const StyledModal = Modal.styled`
  background-color: white;
  opacity: ${(props: StyledModalProps) => props.opacity};
  transition: all ease 200ms;
  min-width: 420px;
  max-height: 100%;
  overflow: auto;
  border-radius: 10px;

  @media (max-width: 450px) {
    min-width: 100%;
    width: 100%;
  }

  @media (min-width: 850px) {
    min-width: 500px;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 30px;
  padding-left: 40px;
  padding-bottom: 5px;

  h2 {
    padding-right: 5px;
  }

  @media (max-width: 400px) {
    padding: 30px 10px 0 30px;
    h2 {
      font-size: ${20 / 16}rem;
    }
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin-right: 5px;
  transition: all 0.3 ease-out;

  @media (min-width: 800px) {
    padding: 10px;
    margin-right: -12px;
  }

  :hover {
    svg {
      opacity: 0.8;
    }
  }
`;

const Content = styled.div`
  font-weight: 300;
  background: #fff;
  max-width: 500px;
  padding: 0 30px 40px;
  @media (max-width: 400px) {
    padding: 30px;
    padding-top: 0;
  }
`;

const Info = styled.div`
  p {
    color: #5b6b7d;
    text-align: center;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const MainSection = styled.div`
  max-height: 80vh;
  overflow: auto;
  padding: 0 10px;
  p {
    @media (max-width: 400px) {
      font-size: ${14 / 16}rem;
    }
  }
`;

type Props = {
  isOpen?: boolean;
  afterOpen?: () => void;
  beforeClose?: () => void;
  closeModal?: () => void;
  opacity?: number;
  children: React.ReactNode;
  content?: string;
  afterClose?: () => void;
  onBackgroundClick?: () => void;
  header: React.ReactNode;
  standAloneHeader?: boolean;
  contentStyle?: React.CSSProperties;
};

const BasicStyledModal: React.FC<Props> = ({
  isOpen = true,
  afterOpen,
  beforeClose,
  closeModal,
  opacity,
  children,
  content,
  afterClose,
  onBackgroundClick,
  header,
  standAloneHeader = false,
  contentStyle,
}) => (
  <StyledModal
    isOpen={isOpen}
    afterOpen={afterOpen}
    beforeClose={beforeClose}
    onBackgroundClick={onBackgroundClick || closeModal}
    onEscapeKeydown={closeModal}
    //@ts-expect-error
    opacity={opacity}
    afterClose={afterClose}
    role="dialog"
  >
    <TitleDiv>
      {standAloneHeader ? header : <h2>{header}</h2>}
      {closeModal && (
        <CloseButton onClick={closeModal}>
          <Close />
        </CloseButton>
      )}
    </TitleDiv>
    <Content style={contentStyle}>
      <Info>
        <Markdown>{content}</Markdown>
      </Info>
      <MainSection>{children}</MainSection>
    </Content>
  </StyledModal>
);

export default BasicStyledModal;

// @ts-nocheck
import {
  ALL_AUTH_METHODS,
  EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
  AUTH_TYPES,
  FACEBOOK_APP_ID,
} from '../../../constants';
import React, { Component } from 'react';
import { getUrlParams, isValidEmail, isValidUrl } from '../../../utils';
import {
  getAcmCookie,
  getAcmEmail,
  storeEnrollmentLocalStorage,
} from '../../../services';

import { AuthFormContainer } from '../../../components/authFormContainer';
import ErrorMessage from '../../../components/ErrorMessage';
import LoginForm from './loginForm';
import PropTypes from 'prop-types';
import Schemas from '../../../components/formSchemas';
import ThirdPartyLogin from './thirdPartyLogin';
import _ from 'lodash';
import { actions } from '../../../redux';
import axios from 'axios';
import { connect } from 'react-redux';
import BlackfynnAccount from './blackfynnAccount';
import { getUserAgentDetails, getVisitorId } from '../../../utils/fingerprint';
import { withTranslation } from 'react-i18next';
import { simpleUserLogout } from '../../../utils/simpleUserLogout';
import withBrand from '../../../components/withBrandHOC';

export class LogIn extends Component {
  constructor(props) {
    super(props);
    const { email, auth_methods } = props.participant;

    const authMethods = _.isEmpty(auth_methods)
      ? ALL_AUTH_METHODS
      : auth_methods;

    this.state = {
      isLoading: false,
      promptForLogin: props.promptForLogin,
      authMethods,
      email: email,
    };
  }

  componentDidMount() {
    // if Achievement cookie is provided but email not provided
    // fall back to regular log in
    let portalEmail = getAcmEmail();
    if (!isValidEmail(portalEmail)) portalEmail = undefined;

    // currently need both email and acm cookie to work
    // read from cookie once and handle it in redux
    let hasAcmCookie = !!getAcmCookie();
    if (hasAcmCookie && !portalEmail) hasAcmCookie = false;

    this.props.updateAcmInfo({ portalEmail, hasAcmCookie });
    this.setState({ email: portalEmail });
  }

  handleSubmit = (data) => {
    const {
      verifiedSubmit,
      meta: { slug },
    } = this.props;
    // This handles cases like login page where function is not passed.
    if (verifiedSubmit) {
      // remove any existing auth token / cookie info in the browser context from previous sessions
      simpleUserLogout(slug);
      verifiedSubmit(data, 'landing', this.submit);
    } else {
      this.submit(data);
    }
  };

  submit = async (data) => {
    const { email } = data;
    const userAgentDetails = getUserAgentDetails();
    const visitorId = await getVisitorId();
    data = {
      ...data,
      meta: {
        userAgent: userAgentDetails,
        visitorId,
      },
      locale: this.props?.meta?.locale,
    };

    this.setState({ isLoading: true, email });
    if (this.state.promptForLogin) {
      await this.loginUser(data);
    } else {
      await this.createUser(data);
    }
  };

  loginUser = async (data) => {
    const { payload = {}, error } = await this.props.loginUser(data);
    if (error) {
      this.setState({ isLoading: false });
      return;
    }

    this.props.getMeta(
      payload.participant_auth_token,
      payload.enrollment_identifier,
    );
    storeEnrollmentLocalStorage(payload);
    this.handleEducationCallback(payload);

    this.props.updateAcmInfo({
      portalEmail: undefined,
      hasAcmCookie: false,
    });
  };

  createUser = async (data) => {
    const { payload = {}, error } = await this.props.createUser(data);
    if (error) return;

    // new enrollments will return an enrollment_identifier
    if (payload.enrollment_identifier) {
      this.props.getMeta(
        payload.participant_auth_token,
        payload.enrollment_identifier,
      );
      storeEnrollmentLocalStorage(payload);
      this.handleEducationCallback(payload);

      this.props.updateAcmInfo({
        portalEmail: undefined,
        hasAcmCookie: false,
      });
    } // existing enrollment, prompt for login
    else {
      const authMethods = !_.isEmpty(payload.auth_methods)
        ? payload.auth_methods
        : ALL_AUTH_METHODS;

      this.setState({
        isLoading: false,
        promptForLogin: true,
        authMethods: authMethods,
      });
    }
  };

  // handle a configurable callback
  handleEducationCallback = (payload = {}) => {
    const { enrollment_identifier } = payload;
    const url_params = getUrlParams(window.location.search);
    if (
      enrollment_identifier !== '' &&
      !_.has(payload, 'user_identifier') &&
      isValidUrl(url_params.education_callback_url)
    ) {
      axios.post(url_params.education_callback_url, {
        enrollment_identifier,
        ref_code: url_params.ref_code,
        ref_id: url_params.ref_id,
        s_slug: url_params.s_slug,
      });
    }
  };

  watchForEmailChange = ({ email }) => {
    this.props.clearErrors();

    if (!this.props.promptForLogin) {
      if (this.state.promptForLogin && email !== this.state.email) {
        // this.props.promptForLogin: if Billboard prompted user for login originally
        // if changing email when entering password, then clear the email
        this.setState({ promptForLogin: false, email: `` }, () => {
          document.getElementById('t-email').focus();
        });
      }
    }
  };

  render() {
    const { email, promptForLogin, authMethods } = this.state;
    const {
      hero_button = 'Check Eligibility',
      participant: { hasAcmCookie = false } = {},
      hero,
      id,
      autoFocus,
      meta,
      t,
    } = this.props;

    const blackfynnAuth =
      _.has(meta, 'authentication.provider') &&
      _.includes(
        EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
        meta.authentication.provider.toLowerCase(),
      );
    const hasBasicAuth = _.includes(authMethods, AUTH_TYPES.basic);
    const hasFbAuth = _.includes(authMethods, AUTH_TYPES.facebook);
    const message = hasFbAuth && authMethods.length === 1 ? '' : 'or';
    const defaultHeroBtnMsg = t('components.loginForm.heroButton');
    const config = {
      displayPasswordLink: promptForLogin,
      hero_button: promptForLogin ? defaultHeroBtnMsg : hero_button,
      isLoading: this.state.isLoading,
      hasAcmCookie,
    };

    const emailPlaceholder = t('components.loginForm.emailPlaceholder');
    const passwordPlaceholder = t('components.loginForm.achievementPassword', {
      company_name: this.props.companyName,
    });

    return (
      <AuthFormContainer>
        <ErrorMessage error={_.head(this.props.errors)} />

        {!promptForLogin && !blackfynnAuth && (
          <LoginForm
            form={id + '_enroll'}
            schema={Schemas.formEnroll(hasAcmCookie, emailPlaceholder)}
            submit={this.handleSubmit}
            onChange={this.watchForEmailChange}
            initialValues={{ email }}
            config={config}
            hero={hero}
            autoFocus={autoFocus}
            formAction="landing"
          />
        )}
        {promptForLogin && !blackfynnAuth && (
          <React.Fragment>
            {hasBasicAuth && (
              <LoginForm
                form={id + '_basic_auth'}
                schema={Schemas.formLogin(
                  emailPlaceholder,
                  passwordPlaceholder,
                )}
                submit={this.handleSubmit}
                onChange={this.watchForEmailChange}
                initialValues={{ email }}
                config={config}
                hero={hero}
              />
            )}
            {hasFbAuth && (
              <ThirdPartyLogin
                facebookAppId={FACEBOOK_APP_ID}
                email={email}
                submit={this.submit}
                message={message}
              />
            )}
          </React.Fragment>
        )}

        {blackfynnAuth && (
          <BlackfynnAccount
            form={`${id}_blackfynn_enroll`}
            initialValues={{ email }}
            config={config}
            studySlug={meta.slug}
            hero={hero}
            authProvider={meta.authentication.provider}
          />
        )}
      </AuthFormContainer>
    );
  }
}

LogIn.defaultProps = {
  error: ``,
  promptForLogin: false,
  hasFacebookLogin: false,
  id: `login`,
  autoFocus: false,
};

LogIn.propTypes = {
  error: PropTypes.string,
  promptForLogin: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default connect(
  ({ participant, errors, meta }) => ({
    participant,
    errors,
    meta,
  }),
  (dispatch) => ({
    getMeta: (auth_token, enrollment_identifier) =>
      dispatch(actions.getMeta(auth_token, enrollment_identifier)),
    loginUser: (user) => dispatch(actions.loginUser(user)),
    createUser: (user) => dispatch(actions.createUser(user)),
    clearErrors: () => dispatch(actions.clearErrors()),
    updateAcmInfo: (payload) => dispatch(actions.updateAcmInfo(payload)),
  }),
)(withBrand(withTranslation()(LogIn)));

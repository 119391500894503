// @ts-nocheck
import React, { useState } from 'react';
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { BigBlueButton } from '../../../components/header/headerLinks';
import { NavLinkStyle } from '../../../components/NavLink.styles';
import CuriousPanda from '../../../components/CuriousPanda';
import Schemas from '../../../components/formSchemas';
import api from '../../../services';
import { AWS_COGNITO_PROVIDER } from '../../../constants';
import { Trans, useTranslation } from 'react-i18next';
import { logError } from '../../../utils/errorLogger';

// Need to use important only here because of styling of spans
// in the Hero banner
const StyledLink = styled.span`
  ${NavLinkStyle}
  display: inline !important;
  padding: 0;
  font-size: 1rem;
`;

const ErrorText = styled.p`
  color: #be322e;
`;

// This component has a dependency on the cognito component being loaded
const BlackFynnAccount = ({ config, studySlug, authProvider, ...rest }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.blackfynn',
  });

  const [accountExists, setAccountExists] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isAWSAuthProvider =
    authProvider.toLocaleLowerCase() === AWS_COGNITO_PROVIDER;

  const checkAccount = (data) => {
    setError(false);
    api
      .accounts(studySlug, { ...data, provider: authProvider })
      .then(({ data }) => {
        window.location.replace(data.onboarding_link);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        if (error.response?.status === 409) {
          setAccountExists(true);
        }
        logError(error);
      });
  };

  return (
    <CuriousPanda
      enableReinitialize={true}
      destroyOnUnmount={true}
      keepDirtyOnReinitialize={true}
      forceUnregisterOnUnmount={false}
      schema={Schemas.blackfynnAccountCreate(t('emailPlaceholder'))}
      onSubmit={(data) => {
        setLoading(true);
        if (isAWSAuthProvider) {
          Auth.federatedSignIn();
        } else {
          checkAccount(data);
        }
      }}
      {...rest}
    >
      {({ invalid, pristine, submitting }) => {
        const { hero_button } = config;
        let enabled = _.isEmpty(rest.initialValues)
          ? pristine || submitting
          : !invalid || submitting;

        return (
          <>
            {error && accountExists && (
              <p>
                <Trans
                  t={t}
                  i18nKey="errors.accountExists"
                  components={{
                    Link: (
                      <StyledLink
                        onClick={() =>
                          Auth.federatedSignIn({ provider: authProvider })
                        }
                      />
                    ),
                  }}
                />
              </p>
            )}
            {error && !accountExists && (
              <ErrorText>{t('errors.defaultMessage')}</ErrorText>
            )}
            {!error && (
              <BigBlueButton
                isLoading={submitting || loading}
                buttonStyle="achievement"
                id={`t-submit_button_${rest.form}`}
                type="submit"
                disabled={!enabled}
                hero={rest.hero}
              >
                {hero_button}
              </BigBlueButton>
            )}
          </>
        );
      }}
    </CuriousPanda>
  );
};

export default BlackFynnAccount;
